import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

class NotFoundPage extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="404: Not Found" />
        <h1>404 error</h1>
        <p>/'fɔr o fɔr ɛrər'</p>
        <h4>noun:</h4>
        <p>A common web pattern that either means you clicked a bad link and a page doesn't exist, or shit's gone sideways in engineering and someone* is about to get woken up in the middle of the night.</p>
        <h3><a href="/">Click here to go back to a page that works,</a>  hopefully.</h3>
        <h6>*It was probably Steve's fault. Get it together, Steve.</h6>

      </Layout>
    )
  }
}

export default NotFoundPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
